<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      width="900px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">Stands libres</span>
        </v-card-title>
        <v-card-text>
          <div class="row" style="min-height: 500px;">
            <div class="col-12">
              <v-simple-table dense v-if="freeStands.length>0">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>
                    </th>
                    <th class="text-left">
                      Espacio
                    </th>
                    <th class="text-left">
                      Código
                    </th>
                    <th class="text-left">
                      Categoría
                    </th>
                    <th class="text-left">
                      Metros cuadrados
                    </th>
                    <th class="text-left">
                      Total valor en COP ({{ taxNomenclature }} incluido)
                    </th>
                    <th v-if="appCountry === 'Perú'" class="text-left">
                      Total valor en S/ ({{ taxNomenclature }} incluido)
                    </th>
                    <th class="text-left">
                      Total valor en USD ({{ taxNomenclature }} incluido)
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="free in freeStands"
                    :key="free.id"
                  >
                    <td>
                      <v-checkbox v-model="free.isChecked"></v-checkbox>
                    </td>
                    <td>{{ free.placeName }}</td>
                    <td>{{ free.code }}</td>
                    <td>{{ free.categoryName }}</td>
                    <td>{{ free.area }}</td>
                    <td class="text-right">
                      ${{ free.copValue + free.copTax | currency }}
                    </td>
                    <td v-if="appCountry === 'Perú'" class="text-right">
                      S/ {{ free.penValue + free.penTax | currency }}
                    </td>
                    <td class="text-right">
                      {{ free.usdValue + free.usdTax | currency }} USD
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="select"
          >
            Agregar selección
          </v-btn>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import { STAND_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'

export default {
  name: 'FreeStandsModal',
  data () {
    return {
      appCountry: process.env.VUE_APP_COUNTRY,
      taxNomenclature: process.env.VUE_APP_TAX_NOMENCLATURE,
      stands: [],
      freeStands: [],
      isActive: false
    }
  },
  mixins: [loaderMixin, notificationMixin, crudServiceMixin],
  methods: {
    async open (bookingId, stands) {
      try {
        this.isActive = true
        this.stands = stands
        this.freeStands = []
        this.showLoader()
        await this.getFreeStands(bookingId)
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    select () {
      const selectedStands = this.freeStands.filter((s) => {
        return s.isChecked === true
      })
      this.$emit('onSelectStands', selectedStands)
      this.isActive = false
    },
    close () {
      this.isActive = false
    },
    async getFreeStands (bookingId) {
      const response = await this.get(STAND_URL + '/free', { params: { bookingId: bookingId } })
      this.freeStands = response.data.filter(fs => {
        const index = this.stands.findIndex(s => {
          return s.id === fs.id
        })
        return index === -1
      })
    }
  }
}
</script>
<style lang="scss">
.input-group--selection-controls__ripple {
  border-radius: 0 !important;
}
</style>
